import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import { ChartColorTheme } from "../../models/ChartColorTheme";
import useWindowDimensions from "../../app/hooks";
import { smallWidth } from "../../utils/Const";

interface LineChartProps {
    data: ChartData[];
    colors: ChartColorTheme;
    style?: CSSProperties;
}



export function LineChart ({data, colors, style} : LineChartProps){

    const {width} = useWindowDimensions();
    const tooltip: EChartOption.Tooltip = {trigger: width > smallWidth ? 'axis':'none'}; 
    const fontSize = width < smallWidth ? 10 : 12;

    style = {
        ...style,
        width:'96%'
    }
  
    let options: EChartOption = {
        tooltip: tooltip,
        grid: {
            left: 10,
            top: 10,
            right: 10,
            bottom: 10,
            containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: data.map(el => el.name),
          axisLabel:{
            rotate:45,
            fontSize: fontSize
          }
        },
        yAxis: {
          min: Math.floor(Math.min(...data.map(el => Math.min(el.valueN, el.valueN_1)))*0.9),
          type: 'value',
          axisLabel:{
            fontSize: fontSize
          }
        },
        series: [
          {
            name:'N',
            data: data.map(el => el.valueN),
            lineStyle: {
                color: colors.labelN,
            },
            itemStyle: {
                color: colors.labelN,
            },
            type: 'line',
            smooth:true
          },
          {
            name: 'N-1',
            data: data.map(el => el.valueN_1),
            lineStyle: {
                color: colors.labelN_1,
            },
            itemStyle: {
                color: colors.labelN_1,
            },
            type: 'line',
            smooth:true

          }
        ]
    }
  
    return  (
        // <div>line</div>
        <ReactECharts option={options} style={style}/> 
        //   <div className="BarChart">
        //     <div style={{color:colorTheme.colorN}}>{title}</div>
        //     <div>
        //         <span style={{color:colorTheme.colorN}}>N</span><span style={{color:colorTheme.colorN_1}}>N-1</span>
        //     </div>
        //     <ReactECharts option={options} style={style}/> 
        //   </div>
    )
  
}