import { useNavigate, useParams } from "react-router-dom";
import { BackIcon, PaletteIcon, ShopIcon } from "../components/Icons";
import { MainHeader } from "../components/Headers";
import { TextInput, ToggleSwitch } from "../components/Inputs";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Shop } from "../models/shop";
import { Group } from "../models/group";
import { useState } from "react";

import '../styles/shopGroup.css'
import { createGroup, updateGroup } from "../api/groupApi";
import { updateGroups } from "../features/UserSlice";
import { StandardButton } from "../components/Buttons";
import { getGroupColorTheme } from "../utils/Shops";

export function ShopGroup () {
    const id: number = parseInt(useParams().groupId || '');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    const shops: Shop[] = useAppSelector((state) => state.user.shops);
    const groups: Group[] = useAppSelector((state) => state.user.groups);

    const [formData, setFormData] = useState<Group>({
        id: id,
        name: groups.find(el => el.id === id)?.name || '',
        color: groups.find(el => el.id === id)?.color || null,
        shops: [...(groups.find(el => el.id === id)?.shops || [])]
    });

    const onChangeGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
        let {name, checked} = e.target;
        let idShop = parseInt(name);
        if (!idShop) {
            return;
        }
        let newShops: number[] = formData.shops;
        console.log(checked);
        if (checked) newShops.push(idShop);
        else newShops = newShops.filter(el => el !== idShop);

        setFormData({
            ...formData,
            shops: newShops
        })

    }

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        let {value} = e.target;
        setFormData({...formData, name:value});
    }

    const onChangeColor = (color:string | null) => {
        setFormData({...formData, color:color});
    }

    const onClickSave = () => {
        if (formData.name === "" || formData.shops.length === 0) {
            return;
        }
        console.log(formData);
        if (formData.id > 0) {
            updateGroup(formData)
            .then((res) => {
                console.log(res);
                dispatch(updateGroups(res))
                navigate('/parametres');
            })
            .catch((e) => console.log(e))
        }
        else {
            createGroup(formData)
            .then((res) => {
                console.log(res);
                dispatch(updateGroups(res))
                navigate('/parametres');
            })
            .catch((e) => console.log(e))
            
        }
    }

    return (
        <div className="ShopGroup">
            <MainHeader title={"Groupe"}>
                <button onClick={() => navigate('/parametres')}><BackIcon/></button>
            </MainHeader>
            <div className="form">
                <TextInput
                    className="name"
                    label="Nom"
                    name="nom"
                    value={formData.name}
                    icon={null}
                    onChange={onChangeName}
                    />
                <h4>
                    <PaletteIcon/>
                    <span>Thème couleur :</span>
                </h4>
                <div className="colorTheme">
                    <ColorBlock color={null} currentColor={formData.color} onChangeColor={onChangeColor}/>
                    <ColorBlock color={'purple'} currentColor={formData.color} onChangeColor={onChangeColor}/>
                    <ColorBlock color={'blue2'} currentColor={formData.color} onChangeColor={onChangeColor}/>
                    <ColorBlock color={'orange'} currentColor={formData.color} onChangeColor={onChangeColor}/>
                    <ColorBlock color={'green'} currentColor={formData.color} onChangeColor={onChangeColor}/>
                    <ColorBlock color={'green2'} currentColor={formData.color} onChangeColor={onChangeColor}/>
                    <ColorBlock color={'pink'} currentColor={formData.color} onChangeColor={onChangeColor}/>

                </div>
                <h4>
                    <ShopIcon/>
                    <span>Magasins :</span>
                </h4>
                <div className="shops">
                    {shops.map((s) => {
                        return (
                            <ToggleSwitch
                                key={s.id}
                                name={s.id.toString()}
                                label={s.name}
                                isChecked={formData.shops.includes(s.id)}
                                onChange={onChangeGroup}/>
                        )
                    })
                    }
                </div>
                <div className="submit">
                    <StandardButton 
                    onClick={onClickSave} 
                    label={ formData.id === 0 ? "Ajouter" : "Enregistrer" }
                    disabled={formData.name === "" || formData.shops.length == 0}/>
                </div>
            </div>


    </div>
    )
}

interface ColorBlockProps {
    color: string | null,
    currentColor: string | null,
    onChangeColor: (color:string | null) => void
}

function ColorBlock ({color, currentColor, onChangeColor}: ColorBlockProps) {
    return (
        <div style={getGroupColorTheme(color)} onClick={() => onChangeColor(color)} className={currentColor === color ? "selected" : ""}></div>
    )
}