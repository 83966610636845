import { forwardRef } from "react"

export function MailIcon (props : any) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <path
            d="m4 18 5-6m11 6-5-6M3 8l7.225 4.817c.642.427.962.641 1.309.724.306.074.626.074.932 0 .347-.083.668-.297 1.309-.724L21 8M6.2 19h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C21 17.48 21 16.92 21 15.8V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C19.48 5 18.92 5 17.8 5H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 6.52 3 7.08 3 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 19 5.08 19 6.2 19"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}


export function LockIcon (props : any) {
    return (
        <svg viewBox="2 2 20 20" fill="none" {...props}>
            <path
            d="M12 14.5v2m-5-6.471C7.471 10 8.053 10 8.8 10h6.4c.747 0 1.329 0 1.8.029m-10 0c-.588.036-1.006.117-1.362.298a3 3 0 0 0-1.311 1.311C4 12.28 4 13.12 4 14.8v1.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 21 7.12 21 8.8 21h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 18.72 20 17.88 20 16.2v-1.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311c-.356-.181-.774-.262-1.362-.298m-10 0V8a5 5 0 0 1 10 0v2.029"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
      </svg>
    )
}


export function ShowIcon (props : any){
    return(
        <svg fill="none" viewBox="0 2 16 12" {...props}>
            <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6M6.5 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0" />
            <path d="M8 2C5.422 2 3.43 3.32 2.112 4.65A11 11 0 0 0 .596 6.564c-.173.28-.31.536-.407.75a3 3 0 0 0-.122.31C.04 7.705 0 7.846 0 8s.041.296.067.375a3 3 0 0 0 .122.31c.097.215.234.471.407.751.346.56.854 1.246 1.516 1.914C3.43 12.68 5.422 14 8 14s4.57-1.32 5.888-2.65a11 11 0 0 0 1.516-1.914c.173-.28.31-.536.407-.75a3 3 0 0 0 .122-.31c.026-.08.067-.221.067-.376s-.041-.296-.067-.375a3 3 0 0 0-.122-.31 7 7 0 0 0-.407-.751 11 11 0 0 0-1.516-1.914C12.57 3.32 10.578 2 8 2M1.556 7.933 1.526 8l.03.067c.065.145.17.344.316.58a9.5 9.5 0 0 0 1.306 1.647C4.332 11.458 5.964 12.5 8 12.5s3.668-1.042 4.822-2.206a9.5 9.5 0 0 0 1.306-1.646A5 5 0 0 0 14.473 8a5 5 0 0 0-.346-.648 9.5 9.5 0 0 0-1.305-1.646C11.668 4.542 10.036 3.5 8 3.5S4.332 4.542 3.178 5.706a9.5 9.5 0 0 0-1.306 1.646 5 5 0 0 0-.316.58z" />
            </g>
        </svg>
    )
}

export function HideIcon (props : any){
    return(
        <svg fill="none" viewBox="-0.03 1 16.03 14.03" {...props}>
            <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M2.28 1.22a.75.75 0 00-1.06 1.06l1.664 1.665A10.99 10.99 0 00.977 5.994c-.295.41-.524.791-.683 1.103a4.16 4.16 0 00-.194.431A1.4 1.4 0 000 8c0 .155.041.296.067.375a3 3 0 00.122.31c.097.215.234.471.407.751.346.56.854 1.246 1.516 1.914C3.43 12.68 5.422 14 8 14c1.49 0 2.786-.442 3.868-1.071l1.852 1.851a.75.75 0 101.06-1.06l-4.645-4.646a.383.383 0 00-.008-.007v-.001l-3.19-3.19a.555.555 0 00-.014-.014L2.28 1.22zm3.127 5.248L3.95 5.01a9.444 9.444 0 00-1.754 1.857 7.362 7.362 0 00-.566.911c-.046.09-.08.164-.104.221l.03.067c.065.145.17.344.316.58a9.45 9.45 0 001.306 1.647C4.332 11.458 5.964 12.5 8 12.5c1.028 0 1.95-.264 2.763-.677l-1.23-1.23C9.078 10.858 8.552 11 8 11a3 3 0 01-3-3c0-.553.142-1.079.407-1.532zm2.985 2.985L6.547 7.608A1.62 1.62 0 006.5 8 1.5 1.5 0 008 9.5c.137 0 .269-.016.392-.047zM8 3.5c-.306 0-.602.023-.887.067a.75.75 0 11-.226-1.483C7.247 2.029 7.617 2 8 2c2.578 0 4.57 1.32 5.888 2.65.662.668 1.17 1.354 1.516 1.914.173.28.31.536.407.75.048.107.09.212.122.31.026.08.067.221.067.376 0 .21-.079.42-.126.537a4.99 4.99 0 01-.251.524c-.21.384-.515.857-.909 1.355a.75.75 0 11-1.176-.93c.343-.434.6-.836.768-1.144.078-.143.133-.258.168-.342a5.336 5.336 0 00-.346-.648 9.452 9.452 0 00-1.306-1.646C11.668 4.542 10.036 3.5 8 3.5zm6.511 4.604l-.002-.008.001.005.001.003z" />
            </g>
        </svg>
    )
}

export function BarChartIcon (props : any){
    return(
        <svg
            viewBox="2 2 20 20"
            fill="none"
            {...props}
        >
            <path
            d="M10 4h4v16h-4V4Zm4 5h4v11h-4V9Zm-8 4h4v7H6v-7Zm-3 7h18"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function PolarChartIcon (props : any){
    return(
        <svg viewBox="1 1 22 22" fill="none" {...props}>
            <path
            d="M11.25 2a.75.75 0 0 1 .75-.75c5.937 0 10.75 4.813 10.75 10.75S17.937 22.75 12 22.75 1.25 17.937 1.25 12a10.72 10.72 0 0 1 3.225-7.677.75.75 0 1 1 1.05 1.071A9.22 9.22 0 0 0 2.75 12 9.25 9.25 0 1 0 12 2.75a.75.75 0 0 1-.75-.75Z"
            fill="currentColor"
            />
            <path
            d="M11.25 5a.75.75 0 0 1 .75-.75A7.75 7.75 0 1 1 4.25 12a.75.75 0 0 1 1.5 0A6.25 6.25 0 1 0 12 5.75a.75.75 0 0 1-.75-.75Z"
            fill="currentColor"
            />
            <path
            d="M12 7.25a.75.75 0 0 0 0 1.5 3.25 3.25 0 0 1 0 6.5.75.75 0 0 0 0 1.5 4.75 4.75 0 1 0 0-9.5Z"
            fill="currentColor"
            />
        </svg>
    )
}

export function RadarChartIcon (props : any){
    return(
        <svg fill="currentColor" viewBox="80 80 864 864" className="icon" {...props}>
            <path d="m926.8 397.1-396-288a31.81 31.81 0 0 0-37.6 0l-396 288a31.99 31.99 0 0 0-11.6 35.8l151.3 466a32 32 0 0 0 30.4 22.1h489.5c13.9 0 26.1-8.9 30.4-22.1l151.3-466c4.2-13.2-.5-27.6-11.7-35.8zM838.6 417l-98.5 32-200-144.7V199.9L838.6 417zM466 567.2l-89.1 122.3-55.2-169.2L466 567.2zm-116.3-96.8L484 373.3v140.8l-134.3-43.7zM512 599.2l93.9 128.9H418.1L512 599.2zm28.1-225.9 134.2 97.1L540.1 514V373.3zM558 567.2l144.3-46.9-55.2 169.2L558 567.2zm-74-367.3v104.4L283.9 449l-98.5-32L484 199.9zM169.3 470.8l86.5 28.1 80.4 246.4-53.8 73.9-113.1-348.4zM327.1 853l50.3-69h269.3l50.3 69H327.1zm414.5-33.8-53.8-73.9 80.4-246.4 86.5-28.1-113.1 348.4z" />
        </svg>
    )
}

export function LineChartIcon (props : any){
    return(
        <svg viewBox="2 2 20 20" fill="none" {...props}>
            <path
            d="M21 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 20.24 3 19.96 3 19.4V3m17 5-3.919 4.183c-.148.158-.223.237-.312.278a.5.5 0 0 1-.253.044c-.098-.01-.194-.06-.387-.16l-3.258-1.69c-.193-.1-.289-.15-.387-.16a.5.5 0 0 0-.253.044c-.09.04-.164.12-.312.278L7 15"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function TrendDownIcon (props : any){
    return(
        <svg viewBox="1 1 22 22" fill="none" {...props}>
            <path
            d="m22 17-7.869-7.869c-.396-.396-.594-.594-.822-.668a1 1 0 0 0-.618 0c-.228.074-.426.272-.822.668L9.13 11.87c-.396.396-.594.594-.822.668a1 1 0 0 1-.618 0c-.228-.074-.426-.272-.822-.668L2 7m20 10h-7m7 0v-7"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function TrendUpIcon (props : any){
    return(
        <svg viewBox="1 1 22 22" fill="none" {...props}>
            <path
            d="m22 7-7.869 7.869c-.396.396-.594.594-.822.668a1 1 0 0 1-.618 0c-.228-.074-.426-.272-.822-.668L9.13 12.13c-.396-.396-.594-.594-.822-.668a1 1 0 0 0-.618 0c-.228.074-.426.272-.822.668L2 17M22 7h-7m7 0v7"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function PrevIcon (props: any){
    return(
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <path
            d="m15 6-5.787 5.787v0a.3.3 0 0 0 0 .426v0L15 18"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function NextIcon (props: any){
    return(
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <path
            d="m9 18 5.787-5.787v0a.3.3 0 0 0 0-.426v0L9 6"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function RefreshIcon (props: any){
    return(
        <svg viewBox="2 2 20 20" fill="none" {...props}>
            <path
            d="M20.984 10H17m3.984 0V6m0 4-3.327-3.657A8 8 0 1 0 19.418 15"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function BackIcon (props: any){
    return(
        <svg viewBox="3 3 42 42" fill="none" {...props}>
            <path
            d="m13 8-7 6 7 7"
            stroke="currentColor"
            strokeWidth={6}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <path
            d="M6 14h22.994c6.883 0 12.728 5.62 12.996 12.5.284 7.27-5.723 13.5-12.996 13.5H11.998"
            stroke="currentColor"
            strokeWidth={6}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}


export function HeartIcon (props: any){
    return(
        <svg  
            fill="currentColor"
            viewBox="1 1 30 30" 
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}>
            <path d="M16.042 8.345s-2-4.262-6.5-4.262c-4.917 0-7.5 4.167-7.5 8.333 0 6.917 14 15.5 14 15.5s13.916-8.5 13.916-15.5c0-4.25-2.666-8.333-7.416-8.333s-6.5 4.262-6.5 4.262z" />
        </svg>
    )
}

export function SkisIcon (props: any){
    return(
        <svg
            fill="currentColor"
            viewBox="0 0 222.92 222.92"
            xmlSpace="preserve"
            {...props}
        >
            <path d="M96.127 0h-.143c-6.672 0-12.1 5.428-12.1 12.1v205.82a5 5 0 0 0 5 5h14.342a5 5 0 0 0 5-5V12.1C108.227 5.428 102.799 0 96.127 0m7.1 157.767a7.17 7.17 0 1 1-14.342 0V124.87a7.17 7.17 0 1 1 14.342 0zM127.931 0h-.144c-6.672 0-12.1 5.428-12.1 12.1v205.82a5 5 0 0 0 5 5h14.343a5 5 0 0 0 5-5V12.1c.001-6.672-5.427-12.1-12.099-12.1m7.099 157.767a7.17 7.17 0 1 1-14.342 0V124.87a7.17 7.17 0 1 1 14.342 0zm-68.463 37.808a5 5 0 0 0-6.938-1.366l-.273.183V46.961h.373a5 5 0 0 0 5-5V10.768c0-5.69-4.629-10.32-10.319-10.32h-.107c-5.69 0-10.32 4.629-10.32 10.32v26.838c-5.846 1.118-15.697 4.132-21.337 12.122-3.409 4.83-3.946 10.604-1.438 15.447 2.274 4.388 6.658 7.115 11.443 7.115 4.265 0 8.431-2.168 11.729-6.104a43.5 43.5 0 0 0 4.976-7.322v142.241l-4.311 2.893a5 5 0 0 0 2.79 9.152c.51 0 1.023-.084 1.521-.244v5.015a5 5 0 0 0 10 0v-11.484l5.846-3.923a5.003 5.003 0 0 0 1.365-6.939M36.714 59.763c-1.365 1.629-2.808 2.526-4.063 2.526-1.236 0-2.135-.886-2.565-1.715-.769-1.483-.503-3.335.729-5.08 2.938-4.164 8.105-6.323 12.225-7.427-.924 3.221-2.744 7.421-6.326 11.696m163.561-10.035c-5.641-7.992-15.496-11.006-21.342-12.123V10.768c0-5.69-4.629-10.32-10.319-10.32h-.107c-5.69 0-10.32 4.629-10.32 10.32v31.193a5 5 0 0 0 5 5h.374v147.432l-.274-.184a5 5 0 1 0-5.572 8.304l5.846 3.923v11.484a5 5 0 0 0 10 0v-5.015a5 5 0 0 0 1.52.244 5 5 0 0 0 2.79-9.152l-4.311-2.893V58.855a43.5 43.5 0 0 0 4.98 7.33c3.297 3.936 7.462 6.104 11.728 6.104h.001c4.784 0 9.168-2.726 11.442-7.113 2.511-4.842 1.973-10.617-1.436-15.448m-7.442 10.848c-.43.829-1.328 1.714-2.563 1.714-1.256 0-2.699-.897-4.064-2.526-3.607-4.305-5.428-8.494-6.346-11.701 4.125 1.104 9.302 3.263 12.246 7.433 1.231 1.744 1.496 3.596.727 5.08" />
        </svg>
    )
}

export function EuroIcon (props: any){
    return(
        <svg viewBox="-1 0 20 20" fill="none" {...props}>
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.552 11a9.6 9.6 0 0 1 0-2H1.5a1.5 1.5 0 0 1 0-3h1.88A9.5 9.5 0 0 1 12 .5c1.761 0 3.456.482 4.93 1.378a1.5 1.5 0 1 1-1.559 2.563A6.46 6.46 0 0 0 12 3.5 6.49 6.49 0 0 0 6.876 6H10a1.5 1.5 0 0 1 0 3H5.576a6.6 6.6 0 0 0 0 2H10a1.5 1.5 0 0 1 0 3H6.876A6.49 6.49 0 0 0 12 16.5a6.46 6.46 0 0 0 3.371-.941 1.5 1.5 0 1 1 1.56 2.563A9.46 9.46 0 0 1 12 19.5 9.5 9.5 0 0 1 3.38 14H1.5a1.5 1.5 0 0 1 0-3z"
            fill="currentColor"
            />
        </svg>
    )
}


export function PalmaresIcon (props: any){
    return(
        <svg fill="currentColor" viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
            <path d="M372.87 445.217H139.13c-18.442 0-33.391 14.949-33.391 33.391v16.696c0 9.22 7.475 16.696 16.696 16.696h267.13c9.22 0 16.696-7.475 16.696-16.696v-16.696c0-18.441-14.949-33.391-33.391-33.391M489.739 33.391h-83.478V16.696c0-9.22-7.475-16.696-16.696-16.696h-267.13c-9.22 0-16.696 7.475-16.696 16.696v16.696H22.261c-9.217 0-16.696 7.473-16.696 16.696v66.783c0 64.441 52.424 116.87 116.87 116.87h8.643c17.853 26.664 44.068 47.155 74.835 58.049v53.256c-18.442 0-33.391 14.949-33.391 33.391v33.391h166.957v-33.391c0-18.442-14.949-33.391-33.391-33.391V291.79c30.768-10.893 56.982-31.385 74.835-58.049h8.643c64.445 0 116.87-52.429 116.87-116.87V50.087c-.001-9.223-7.48-16.696-16.697-16.696M38.957 116.87V66.783h66.783v83.478c0 17.436 3.122 34.105 8.583 49.676-42.239-4.095-75.366-39.773-75.366-83.067m294.717 23.897-26.729 26.049 6.315 36.788a16.69 16.69 0 0 1-6.642 16.326 16.71 16.71 0 0 1-17.587 1.272L256 203.837l-33.033 17.365a16.69 16.69 0 0 1-17.587-1.272 16.69 16.69 0 0 1-6.642-16.326l6.315-36.788-26.729-26.049a16.7 16.7 0 0 1-4.228-17.114 16.71 16.71 0 0 1 13.478-11.364l36.935-5.369 16.522-33.467a16.68 16.68 0 0 1 14.967-9.31 16.67 16.67 0 0 1 14.967 9.31l16.522 33.467 36.935 5.369a16.71 16.71 0 0 1 13.478 11.364 16.7 16.7 0 0 1-4.226 17.114m139.369-23.897c0 43.294-33.128 78.973-75.365 83.068 5.461-15.571 8.583-32.24 8.583-49.676V66.783h66.783v50.087z" />
        </svg>
    )
}

export const WarningIcon = forwardRef((props: any, ref) =>{
    return(
        <svg viewBox="25 25 462 462" {...props} ref={ref}>
            <path
            d="M278.313 48.296a42.67 42.67 0 0 1 15.876 15.876l182.478 319.336c11.691 20.46 4.583 46.523-15.876 58.214a42.67 42.67 0 0 1-21.169 5.622H74.667C51.103 447.344 32 428.241 32 404.677a42.67 42.67 0 0 1 5.622-21.169L220.099 64.172c11.691-20.459 37.755-27.567 58.214-15.875M256 314.667c-15.238 0-26.667 11.264-26.667 26.283 0 15.701 11.083 26.965 26.667 26.965 15.238 0 26.667-11.264 26.667-26.624S271.238 314.667 256 314.667m21.333-165.333h-42.666v128h42.666z"
            fill="currentColor"
            fillRule="evenodd"
            />
        </svg>
    )
})

export const LogoutIcon = forwardRef((props: any, ref) =>{
    return(
        <svg viewBox="1 1 22 22" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035q-.016-.005-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427q-.004-.016-.017-.018m.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093q.019.005.029-.008l.004-.014-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014-.034.614q.001.018.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01z" />
                <path
                d="M13.5 3a1.5 1.5 0 0 0-3 0v10a1.5 1.5 0 0 0 3 0zM7.854 5.75a1.5 1.5 0 1 0-1.661-2.5A10.49 10.49 0 0 0 1.5 12c0 5.799 4.701 10.5 10.5 10.5S22.5 17.799 22.5 12c0-3.654-1.867-6.87-4.693-8.75a1.5 1.5 0 0 0-1.66 2.5 7.5 7.5 0 1 1-8.292 0"
                fill="currentColor"
                />
            </g>
        </svg>
    )
})

export function SearchIcon (props: any){
    return(
        <svg viewBox="3 3 18 18" fill="none" {...props}>
            <circle cx={11} cy={11} r={7} stroke="currentColor" strokeWidth={2} />
            <path
            d="M11 8a3 3 0 0 0-3 3m12 9-3-3"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            />
        </svg>
    )
}

export function XIcon (props: any){
    return(
        <svg fill="currentColor" viewBox="64 64 896 896" {...props}>
            <path d="M512.481 421.906 850.682 84.621c25.023-24.964 65.545-24.917 90.51.105s24.917 65.545-.105 90.51L603.03 512.377 940.94 850c25.003 24.984 25.017 65.507.033 90.51s-65.507 25.017-90.51.033L512.397 602.764 174.215 940.03c-25.023 24.964-65.545 24.917-90.51-.105s-24.917-65.545.105-90.51l338.038-337.122L84.14 174.872c-25.003-24.984-25.017-65.507-.033-90.51s65.507-25.017 90.51-.033z" />
        </svg>
    )
}


export function DuplicateIcon (props: any){
    return(
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 6h-2V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h3v2H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3zm-2 8h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 1 1 2 0zM9 6h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3m0 2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"
            fill="currentColor"
            />
        </svg>
    )
}

export function ShopIcon (props: any){
    return(
        <svg viewBox="2 2 20 20" {...props}>
            <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                d="M4 12v7a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7M9 8h6v1a3 3 0 0 1-3 3h0a3 3 0 0 1-3-3zm6 0h5.556c.245 0 .444.199.444.444V9a3 3 0 0 1-3 3h0a3 3 0 0 1-3-3zM3 8.444C3 8.2 3.199 8 3.444 8H9v1a3 3 0 0 1-3 3h0a3 3 0 0 1-3-3zm.826-5.033A.5.5 0 0 1 4.318 3h15.364a.5.5 0 0 1 .492.411l.72 4a.5.5 0 0 1-.492.589H3.598a.5.5 0 0 1-.492-.589z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
            />
            <path
                d="M9 16a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5H9z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
            />
            </g>
        </svg>
    )
}

export function GroupIcon (props: any){
    return(
        <svg viewBox="1.9 1.9 20.2 20.2" fill="none" {...props}>
            <path
            d="M9 6a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm-4.562 7.902a3 3 0 1 0 3 5.195 3 3 0 0 0-3-5.196Zm15.124 0a2.999 2.999 0 1 1-2.998 5.194 2.999 2.999 0 0 1 2.998-5.194Z"
            stroke="currentColor"
            strokeWidth={2}
            />
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.07 6.643a3 3 0 0 1 .42-2.286 9 9 0 0 0-6.23 10.79 3 3 0 0 1 1.77-1.506 7 7 0 0 1 4.04-6.998m5.86 0a7 7 0 0 1 4.04 6.998 3 3 0 0 1 1.77 1.507 9 9 0 0 0-6.23-10.79 3 3 0 0 1 .42 2.285m3.3 12.852a3 3 0 0 1-2.19-.779 7 7 0 0 1-8.08 0 3 3 0 0 1-2.19.78 9 9 0 0 0 12.46 0"
            fill="currentColor"
            />
        </svg>
    )
}

export function ChevronIcon (props: any){
    return(
        <svg viewBox="80 80 864 864" {...props}>
            <path
            fill="currentColor"
            d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496"
            />
        </svg>
    )
}

export function CalendarIcon (props: any){
    return(
        <svg viewBox="2 2 20 20" fill="none" {...props}>
            <path
            d="M3 9h18M7 3v2m10-2v2M6 12h2m3 0h2m3 0h2M6 15h2m3 0h2m3 0h2M6 18h2m3 0h2m3 0h2M6.2 21h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C21 19.48 21 18.92 21 17.8V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C19.48 5 18.92 5 17.8 5H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 6.52 3 7.08 3 8.2v9.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 21 5.08 21 6.2 21Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            />
        </svg>
    )
}

export function SwiperIcon (props: any){
    return(
        <svg fill="currentColor" viewBox="2 2 20 20" {...props}>
            <path d="M4 19h2c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2h2c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2h-2c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2M20 7v10h-2V7zM8 5h8l.001 14H8zM4 7h2v10H4z" />
        </svg>
    )
}


export function SettingsIcon (props: any){
    return(
        <svg viewBox="2 2 20 20" fill="none" {...props}>
            <path
            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <path
            d="M12.905 3.06C12.699 3 12.465 3 12 3c-.466 0-.699 0-.905.06a1.5 1.5 0 0 0-.76.515c-.133.169-.22.385-.392.818a1.265 1.265 0 0 1-1.574.73l-.571-.19c-.405-.135-.608-.203-.806-.214a1.5 1.5 0 0 0-.835.197c-.172.099-.323.25-.625.552-.32.32-.481.48-.583.664a1.5 1.5 0 0 0-.181.884c.021.208.105.418.274.84.264.66.01 1.413-.6 1.778l-.277.167c-.425.255-.637.382-.791.558a1.5 1.5 0 0 0-.303.534C3 11.116 3 11.366 3 11.866c0 .593 0 .89.095 1.143a1.5 1.5 0 0 0 .396.586c.2.182.473.29 1.018.508.556.223.843.841.653 1.41l-.215.645c-.149.447-.223.671-.23.89a1.5 1.5 0 0 0 .179.759c.104.193.27.36.604.693s.5.5.693.604a1.5 1.5 0 0 0 .758.18c.22-.008.444-.082.89-.231l.528-.176a1.265 1.265 0 0 1 1.574.73c.173.433.26.65.392.818a1.5 1.5 0 0 0 .76.515c.206.06.44.06.905.06.466 0 .699 0 .905-.06a1.5 1.5 0 0 0 .76-.515c.133-.169.22-.385.392-.818a1.265 1.265 0 0 1 1.574-.73l.527.176c.447.149.67.223.89.23a1.5 1.5 0 0 0 .758-.179c.194-.104.36-.27.694-.604s.5-.5.604-.693a1.5 1.5 0 0 0 .179-.758c-.007-.22-.082-.444-.23-.89l-.216-.646a1.133 1.133 0 0 1 .654-1.41c.545-.217.817-.326 1.018-.508a1.5 1.5 0 0 0 .396-.586c.095-.254.095-.55.095-1.143 0-.5 0-.75-.071-.973a1.5 1.5 0 0 0-.302-.534c-.155-.176-.367-.303-.792-.558l-.278-.167a1.45 1.45 0 0 1-.6-1.778c.17-.422.253-.632.275-.84a1.5 1.5 0 0 0-.181-.884c-.102-.183-.263-.344-.583-.664-.302-.302-.453-.453-.625-.552a1.5 1.5 0 0 0-.835-.197c-.199.011-.401.079-.806.214l-.57.19a1.265 1.265 0 0 1-1.575-.73c-.173-.433-.26-.65-.392-.818a1.5 1.5 0 0 0-.76-.515"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function EditIcon (props: any){
    return(
        <svg viewBox="1 1 22 22" fill="none" {...props}>
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.848 1.879a3 3 0 0 0-4.243 0L2.447 16.036a3 3 0 0 0-.82 1.533l-.587 2.936a2 2 0 0 0 2.353 2.353l2.936-.587a3 3 0 0 0 1.533-.82L22.019 7.293a3 3 0 0 0 0-4.243zm-2.829 1.414a1 1 0 0 1 1.415 0l1.171 1.171a1 1 0 0 1 0 1.415L17.933 8.55l-2.585-2.586zm-4.086 4.086L3.862 17.45a1 1 0 0 0-.274.51l-.587 2.936 2.935-.587a1 1 0 0 0 .511-.274L16.52 9.964z"
            fill="currentColor"
            />
        </svg>
    )
}

export function PlusIcon (props: any){
    return(
        <svg viewBox="1 1 22 22" fill="none" {...props}>
            <path
            d="M12 6a1 1 0 0 1 1 1v4h4a1 1 0 1 1 0 2h-4v4a1 1 0 1 1-2 0v-4H7a1 1 0 1 1 0-2h4V7a1 1 0 0 1 1-1"
            fill="currentColor"
            />
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12m11-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18"
            fill="currentColor"
            />
        </svg>
    )
}

export function PaletteIcon (props: any){
    return(
        <svg viewBox="2 2 20 20" fill="none" {...props}>
            <path
            d="M15.5 8.5h.01m-5.01-1h.01m-3.01 4h.01M12 21a9 9 0 1 1 9-9 3 3 0 0 1-3 3h-.6c-.372 0-.557 0-.713.025a2 2 0 0 0-1.662 1.662c-.025.156-.025.341-.025.713v.6a3 3 0 0 1-3 3m4-12.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-5-1a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-3 4a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}

export function MountainsIcon (props: any){
    return(
        <svg viewBox="0 0 512 512" xmlSpace="preserve" {...props}>
            <circle
            style={{
                fill: "#fff082b0",
            }}
            cx={317.793}
            cy={229.517}
            r={61.793}
            />
            <circle
            style={{
                fill: "#fff8c1b0",
            }}
            cx={317.793}
            cy={229.517}
            r={35.31}
            />
            <path
            style={{
                fill: "#cfd0db",
            }}
            d="m493.084 441.379-152.58-194.194c-7.069-8.996-20.696-8.996-27.765 0l-152.58 194.194z"
            />
            <path
            style={{
                fill: "#f2f9fc",
            }}
            d="m286.897 348.69 42.31-42.31a8.827 8.827 0 0 1 12.483 0l5.172 5.172a8.827 8.827 0 0 0 12.483 0l17.769-17.769-36.611-46.596c-7.069-8.996-20.696-8.996-27.765 0l-47.317 60.222z"
            />
            <path
            style={{
                fill: "#b8baca",
            }}
            d="M503.172 432.552h-104.04L225.745 211.875c-7.069-8.996-20.696-8.996-27.765 0L29.892 425.804a17.66 17.66 0 0 1-13.882 6.748H8.828A8.82 8.82 0 0 0 0 441.379a8.823 8.823 0 0 0 8.828 8.828h494.345a8.823 8.823 0 0 0 8.828-8.828 8.824 8.824 0 0 0-8.829-8.827"
            />
            <path
            style={{
                fill: "#bebfd2",
            }}
            d="M170.933 432.552h228.2L227.654 214.305l-65.14 206.766c-1.792 5.689 2.455 11.481 8.419 11.481"
            />
            <path
            style={{
                fill: "#eaf3fb",
            }}
            d="M172.896 305.309c6.894 6.895 18.073 6.895 24.968 0l19.17-19.17c6.894-6.894 18.073-6.894 24.968 0l1.515 1.515c6.894 6.895 18.073 6.895 24.968 0l9.408-9.408-52.148-66.369c-7.069-8.996-20.696-8.996-27.765 0l-52.148 66.369z"
            />
            <path
            style={{
                fill: "#f2f9fc",
            }}
            d="M243.517 287.654c6.894 6.895 18.073 6.895 24.968 0l9.408-9.408-50.239-63.94-28.154 89.368 17.535-17.534c6.894-6.894 18.073-6.894 24.968 0z"
            />
            <path
            style={{
                fill: "#fff082b0",
            }}
            d="M317.793 141.241a8.826 8.826 0 0 1-8.828-8.828V70.621a8.826 8.826 0 0 1 8.828-8.828 8.826 8.826 0 0 1 8.828 8.828v61.793a8.825 8.825 0 0 1-8.828 8.827m158.897 97.104h-61.793c-4.879 0-8.828-3.953-8.828-8.828s3.948-8.828 8.828-8.828h61.793c4.879 0 8.828 3.953 8.828 8.828s-3.949 8.828-8.828 8.828"
            />
            <path
            style={{
                fill: "#fff8c1b0",
            }}
            d="M366.336 154.25c-1.5 0-3.017-.38-4.405-1.181-4.224-2.44-5.673-7.837-3.233-12.061l22.069-38.225c2.44-4.224 7.844-5.668 12.061-3.233 4.224 2.44 5.673 7.837 3.233 12.061l-22.069 38.225a8.83 8.83 0 0 1-7.656 4.414m35.561 35.543a8.83 8.83 0 0 1-7.656-4.414c-2.44-4.224-.991-9.621 3.233-12.061l38.225-22.069c4.207-2.44 9.612-.995 12.061 3.233 2.44 4.224.991 9.621-3.233 12.061l-38.225 22.069a8.8 8.8 0 0 1-4.405 1.181M269.25 154.25a8.83 8.83 0 0 1-7.656-4.414l-22.069-38.225c-2.44-4.224-.991-9.621 3.233-12.061 4.207-2.435 9.612-.991 12.061 3.233l22.069 38.225c2.44 4.224.991 9.621-3.233 12.061a8.8 8.8 0 0 1-4.405 1.181m170.854 154.716c-1.5 0-3.017-.38-4.405-1.181l-38.225-22.069c-4.224-2.44-5.673-7.837-3.233-12.061 2.44-4.228 7.837-5.677 12.061-3.233l38.225 22.069c4.224 2.44 5.673 7.837 3.233 12.061a8.83 8.83 0 0 1-7.656 4.414M233.689 189.793c-1.5 0-3.017-.38-4.405-1.181l-38.225-22.069c-4.224-2.44-5.673-7.837-3.233-12.061 2.44-4.228 7.844-5.673 12.061-3.233l38.225 22.069c4.224 2.44 5.673 7.837 3.233 12.061a8.83 8.83 0 0 1-7.656 4.414"
            />
        </svg>
    )
}
