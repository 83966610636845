import { ChartColorTheme } from "../models/ChartColorTheme";
import { smallWidth } from "./Const";



export const getChartColorTheme = (chartType:string, theme: string) : ChartColorTheme | string[] | null => {
    switch(chartType) {
        case "bar":
            return getBarChartColorTheme(theme);
        case "polar":
            return getPolarChartColorTheme(theme);
        case "radar":
        case "line": 
            return getRadarChartColorTheme();
        default:
            return null
    }

}

const getBarChartColorTheme = (color : string) : ChartColorTheme =>{
  switch(color){
      case "purple" :
          return {
            backgroundN: "#7c86ec",
            backgroundN_1: "#9ca3ebB0",
            labelN: '#BEBFE6',
            labelN_1: '#7880EC',
            legendN: "#7c86ec",
            legendN_1: "#9ca3ebB0"
          };
      case "orange" : 
          return {
            backgroundN: "#f5a278",
            backgroundN_1: "#f5bfa3b0",
            labelN: '#FFE3D3',
            labelN_1:'#EC945C',
            legendN: "#f5a278",
            legendN_1: "#f5bfa3b0",
          };
      case "green" : 
          return {
            backgroundN: "#b2e36d",
            backgroundN_1: "#d7ecbbB0",
            labelN: '#E4FCBF',
            labelN_1: '#B1CC87',
            legendN: "#b2e36d",
            legendN_1: "#d7ecbbB0",
          };
      default : //bleu
          return {
            backgroundN: "#9DD3F3",
            backgroundN_1: "#BFE3F8B0",
            labelN: '#F4F8FE',
            labelN_1: '#8AB3C7',
            legendN: "#9DD3F3",
            legendN_1: "#BFE3F8B0",
          };
  }
  
}

const getPolarChartColorTheme = (color: string) : string[] => {
    switch(color){
        case "purple" :
            return ['#d1addc', '#c276e1', '#c2bbfc', '#79429c', '#5d4fd1', '#7e13c9', '#926cc8', '#55309f', '#547ae7', '#9eb2e6'];
        case "orange" : 
            return ['#dae6b1', '#f8ff58', '#ffc31c', '#ffde00', '#bf9c4b', '#ebc8b6', '#f2c58e', '#e8c90a', '#faa788', '#f3b2aa'];
        case "green" : 
            return ['#e3f5e3', '#8cdb86', '#b4efaa', '#62c54c', '#e1d39b', '#efffa2', '#93d065', '#b7ff47', '#b2da00', '#e8e863'];
        default : //bleu
            return ['#c7d4e7', '#d1fafc', '#94a7c8', '#d1e8fb', '#26a9de', '#96bdcb', '#2da7ca', '#8ddae9', '#64d3f9', '#a6e8e2'];
    }
}


const getRadarChartColorTheme = () : ChartColorTheme => {
    return {
        backgroundN: '#4958eb33',
        backgroundN_1: "#36a2eb33",
        labelN: "#4958eb",
        labelN_1: "#5eadeb8a",
        legendN: "#4958eb",
        legendN_1: "#5eadeb8a"
    }
}



export const getBarChartHeight = (dataLength : number) : number => {
    const width = window.innerWidth;

    if (dataLength > 10) {
        return (dataLength*30)
    }

    if (dataLength > 5) {
        if (width <= smallWidth) return (dataLength*45);
        return (dataLength*60)
    }

    if (width <= smallWidth) return (dataLength*70);
    return (dataLength*90)
    
}

export const getLineChartHeight = () : number => {
    const width = window.innerWidth;

    if (width <= smallWidth) return 350;
    return 500
    
}
  

export const formatLabels = (labels: string[], maxLength: number) : string[] => {
    return labels.map((el: string) => el.substring(0, maxLength)+(el.length > maxLength ? "..." : ""));
}