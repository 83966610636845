import dayjs from "dayjs";
import { useAppDispatch } from "../app/hooks";
import { Shop } from "../models/shop";
import { ShopData } from "../models/shopData";
import { DashboardWidget } from "../models/widget";
import { RefreshIcon, WarningIcon } from "./Icons";
import { WidgetLoader } from "./Loaders";
import { ErrorTooltip } from "./Tooltips";

import '../styles/widgetContent.css'
import { CSSProperties } from "react";
import { setRefreshTime } from "../features/DashboardSlice";

interface WidgetContentProps {
    date?: string;
    data: ShopData | null;
    widget: DashboardWidget;
    error: boolean;
    children: React.ReactNode
    style?: CSSProperties
}

interface ErrorBlockProps {
    data: ShopData | null;
    children?: React.ReactNode;
    widget: DashboardWidget;
}

interface AllShopsKOErrorProps {
    message: string,
    widget: DashboardWidget;

}

interface ShopKOWarningProps {
    shopsKO: Shop[]
}


export function WidgetContent ({date, data, widget, error, children, style}: WidgetContentProps) {
    return (
        <div className="WidgetContent" style={style}>
            {data == null && !error? 
                <WidgetLoader/> :
                <ErrorBlock data={data} widget={widget}>
                    {date ? <div className='date'>{date}</div> : null}
                    <div className='timestamp'>
                        {data?.timestamp ? ('données actualisées à ' + dayjs(data.timestamp).format('HH:mm')) : null}
                    </div>
                    {children}
                </ErrorBlock>
            }
        </div>
    )
}

function ErrorBlock({children, data, widget}: ErrorBlockProps) {

    return (
        <div>
            {!data || data.key == "" ?
                <div className="ErrorBlock">
                    <AllShopsKOError widget={widget} message = {!data ? "Une erreur s'est produite, veuillez réessayer": "Aucun des magasins sélectionnés n'a répondu"}/>
                </div> 
                : [
                    data.shopsKO.length > 0 ? <ShopKOWarning key={'shopKOWarning'} shopsKO={data.shopsKO}/> : null,
                    children
                ]
            }
        </div>
    )
}


function AllShopsKOError ({message, widget}: AllShopsKOErrorProps) {

    const dispatch = useAppDispatch();

    const onClickRefresh = () => { 
        dispatch(setRefreshTime({id: widget.id, occurence: widget.occurence, refreshTime:new Date().toISOString()}));
    }

    

    return (
        <div className="AllShopsKOError">
            <div className="errorSvg">
                <svg className="circular redStroke"><circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10"/></svg>
                <svg className="cross redStroke"><g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)"><path className="first-line" d="M634.087,300.805L673.361,261.53" fill="none"/></g><g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)"><path className="second-line" d="M634.087,300.805L673.361,261.53"/></g></svg>
            </div>        
            <span>{message}</span>
            <button onClick={onClickRefresh}><RefreshIcon/></button>
        </div>
    )
}


function ShopKOWarning ({shopsKO}: ShopKOWarningProps ) {

    const listShops = shopsKO.reduce ((message,shop) => (message = message+(message.length> 0 ? ", " : "")+shop.name), '')

    return (
        <div className="ShopKOWarning">
            <ErrorTooltip
                title={"Les magasins "+listShops+" n'ont pas répondu. Les données affichées ne tiennent donc pas compte de ces magasins"}
                placement="right">
                <WarningIcon/>
            </ErrorTooltip>
        </div>
    )

}