import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';
import WidgetSlider from '../WidgetSlider';

import { PalmaresData} from "../../models/shopData";
import dayjs from 'dayjs';

import { DashboardWidget } from '../../models/widget';
import ChartBlock from '../ChartBlock';
import { getBarChartHeight } from '../../utils/Charts';
import { smallWidth } from '../../utils/Const';
import useWindowDimensions from '../../app/hooks';
import { getPalmares } from '../../api/palmaresApi';
import { WidgetContent } from '../WidgetContent';
import { getRefresh } from '../../utils/Widgets';

interface PalmaresProps {
    type: string,
    of: string,
    shopId: number,
    widget: DashboardWidget
}

export default function Palmares({shopId, widget, type, of} : PalmaresProps) {

    const [data, setData] = useState<PalmaresData | null>(null);
    const top: number = 10;
    const [error, setError] = useState<boolean>(false);
    const debut = '@EXERCICEDEBUT@';
    const fin = dayjs().format('YYYY-MM-DD');
    const chartTypes: string[] = ['bar', 'polar', 'radar'];

    useEffect(() => {
        setData(null);
        getPalmares(shopId, type, of, debut, fin, top, getRefresh(data?.timestamp || null, widget.refreshTime))
        .then((res) => setData(res))
        .catch((err) => {
            console.log(err);
            setError(true);
        });
        
    }, [widget.refreshTime, debut, fin]);


    return (
       <div className="Widget">
            <WidgetHeader title={type.charAt(0).toUpperCase() + type.slice(1) + " " + of} widget={widget} searchable={false}/>
            <WidgetContent data={data} widget={widget} error={error} style={{minHeight: (useWindowDimensions().width < smallWidth ? 140 : 130 ) + getBarChartHeight(top) +'px'}}>
                {data == null ? 
                    null :
                    <div>
                        <WidgetSlider defaultIndex={0} widget={widget}>
                            <div className='widgetSlide'>
                                <ChartBlock
                                    style={{height: getBarChartHeight(top) +'px'}}
                                    widget={widget}
                                    libelle={"Par quantité"}
                                    chartTypes={chartTypes}
                                    color='blue'
                                    data={data.byQuantite.map (el => {
                                        return {
                                            name: el.nom,
                                            valueN: el.quantiteN,
                                            valueN_1: el.quantiteN_1
                                        }
                                    })}/>       
                            </div>
                            <div className='widgetSlide'>
                                <ChartBlock
                                    style={{height: getBarChartHeight(top) +'px'}}
                                    widget={widget}
                                    libelle={"Par coefficient"}
                                    chartTypes={chartTypes}
                                    color='purple'
                                    data={data.byCoeff.map (el => {
                                        return {
                                            name: el.nom,
                                            valueN: el.coeffN,
                                            valueN_1: el.coeffN_1
                                        }
                                    })}/>       
                            </div>
                            <div className='widgetSlide'>
                                <ChartBlock
                                    style={{height: getBarChartHeight(top) +'px'}}
                                    widget={widget}
                                    libelle={"Par montant"}
                                    chartTypes={chartTypes}
                                    color='orange'
                                    data={data.byMontant.map (el => {
                                        return {
                                            name: el.nom,
                                            valueN: el.montantN,
                                            valueN_1: el.montantN_1
                                        }
                                    })}/>       
                            </div>
                            <div className='widgetSlide'>
                                <ChartBlock
                                    style={{height: getBarChartHeight(top) +'px'}}
                                    widget={widget}
                                    libelle={"Par marge"}
                                    chartTypes={chartTypes}
                                    color='green'
                                    data={data.byMarge.map (el => {
                                        return {
                                            name: el.nom,
                                            valueN: el.margeN,
                                            valueN_1: el.margeN_1
                                        }
                                    })}/>       
                            </div>
                        </WidgetSlider>
                    </div>
                }
            </WidgetContent>

       </div>
    )
}