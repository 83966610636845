import { useState } from "react";
import { login } from "../api/authApi";
import { useAppDispatch } from "../app/hooks";
import { setUser } from "../features/UserSlice";
import { useNavigate } from "react-router-dom";

import '../styles/login.css';
import {PasswordInput, TextInput} from "../components/Inputs";
import { LockIcon, MailIcon } from "../components/Icons";
import { StandardButton } from "../components/Buttons";
import { SmallLoader } from "../components/Loaders";
import { User } from "../models/user";

interface LoginFormData {
  username: string;
  password: string;
}

export default function Login() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean> (false);
  const [error, setError] = useState<boolean>(false);
  const [formData, setFormData] = useState<LoginFormData>({
    username:"",
    password:"",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let {name, value} = e.target;
    setFormData({...formData, [name] : value});
    setError(false);
  }

  const handleSubmit = async () => {
    if (formData.username === "" || formData.password === ""){
      return;
    }

    setError(false);
    setLoading(true);

      await login(formData)
      .then (user => {
        dispatch(setUser(user));
        navigate ('/');

      })
      .catch ((e: Error) => {
        
        if (e?.message === "Unauthorized") setError(true);
        else alert('Une erreur s\'est produite');

      })


    setLoading(false);
  }

  return (
    <div className="Login">
      <div className="loginForm">
        <img src='/skilou.png' className={error ? "imgError" : ""}/>
        <h1>Bienvenue !</h1>
          <form onSubmit={handleSubmit}>
            <TextInput label="Email" name="username" value={formData.username} onChange={onChange} icon={<MailIcon/>}/>
            <PasswordInput label="Mot de passe" name="password" value={formData.password} onChange={onChange} icon={<LockIcon/>}/>
            {loading ? <SmallLoader style={{width:'20px', height:'20px'}}/> : <StandardButton onClick={handleSubmit} label="Connexion" disabled={false}/>}
          </form>
      </div>
    </div>
  );
}