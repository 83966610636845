import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardWidget, DashboardWidgetSlide } from '../models/widget';
import { Dayjs } from 'dayjs';

interface DashboardState  {
    widgets: DashboardWidget[]
}

const initialState: DashboardState = {
        widgets: []
}

interface setCurrentSlidePayload {
    id: number,
    occurence:number,
    slideIndex:number
}

interface setSearchPayload {
    id: number,
    occurence:number,
    search:boolean
}

interface setRefreshPayload {
    id: number,
    occurence:number,
    refresh:boolean
}

interface setRefreshTimePayload {
    id: number,
    occurence:number,
    refreshTime: string | null
}

interface setFixedFramePayload {
    id: number,
    occurence:number,
    fixedFrame:boolean
}

interface setSearchDatePayload {
    id: number,
    occurence:number,
    date: string | null
}

interface setChartTypePayload {
    id: number,
    occurence:number,
    chartType: string
}



export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setWidgets: (state, action : PayloadAction<DashboardWidget[]>) => {
            return{
              ...state,
              widgets: action.payload
            }
        },
        duplicateWidget: (state, action : PayloadAction<DashboardWidget>) => {
            
            let occurence: number = state.widgets
            .filter(el => el.id === action.payload.id)
            .reduce((maxOccurence, widget) => {
                return Math.max(maxOccurence, widget.occurence);
            }, 0) + 1;
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_0");
            let newItem = {...action.payload, occurence:occurence}
            return{
              ...state,
              widgets: [...state.widgets.slice(0, index+1), newItem, ...state.widgets.slice(index+1)]
            }
        },
        removeDuplicatedWidget: (state, action : PayloadAction<DashboardWidget>) => {
            
            return{
              ...state,
              widgets: state.widgets.filter (el => el.id !== action.payload.id || el.occurence !== action.payload.occurence)
            }
        },
        setCurrentSlide: (state, action : PayloadAction<setCurrentSlidePayload>) => {
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_"+action.payload.occurence);
            state.widgets[index].slideIndex = action.payload.slideIndex;
        },
        setSearch: (state, action : PayloadAction<setSearchPayload>) => {
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_"+action.payload.occurence);
            state.widgets[index].search = action.payload.search;
            if (action.payload.search) state.widgets[index].fixedFrame = false;
        },
        setFixedFrame: (state, action : PayloadAction<setFixedFramePayload>) => {
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_"+action.payload.occurence);
            state.widgets[index].fixedFrame = action.payload.fixedFrame;
            if (action.payload.fixedFrame) state.widgets[index].search = false;
        },
        setSearchDateDebut: (state, action : PayloadAction<setSearchDatePayload>) => {
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_"+action.payload.occurence);
            state.widgets[index].searchDateDebut = action.payload.date;
        },
        setSearchDateFin: (state, action : PayloadAction<setSearchDatePayload>) => {
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_"+action.payload.occurence);
            state.widgets[index].searchDateFin = action.payload.date;
        },
        setChartType: (state, action : PayloadAction<setChartTypePayload>) => {
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_"+action.payload.occurence);
            state.widgets[index].chartType = action.payload.chartType;
        },
        setRefreshTime: (state, action : PayloadAction<setRefreshTimePayload>) => {
            let index = state.widgets.map(function(e) { return e.id+"_"+e.occurence; }).indexOf(action.payload.id+"_"+action.payload.occurence);
            state.widgets[index].refreshTime = action.payload.refreshTime;
        },
        unsetWidgets: (state) => {
            return{
              ...state,
              widgets: [],
            }
        },
    }

});

export const { setWidgets, unsetWidgets, duplicateWidget, removeDuplicatedWidget, setCurrentSlide, setSearch, setFixedFrame, setSearchDateDebut, setSearchDateFin, setChartType, setRefreshTime } = dashboardSlice.actions
export default dashboardSlice.reducer