import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';
import WidgetSlider from '../WidgetSlider';

import { PerformancesData } from "../../models/shopData";
import { getPerformancesParCategories } from '../../api/locationApi';
import { DataBlock } from '../DataBlock';
import dayjs from 'dayjs';
import ChartBlock from '../ChartBlock';

import { getDateAsString, getDateRange, getRefresh } from '../../utils/Widgets';
import { getBarChartHeight } from '../../utils/Charts';
import WidgetSearchFrame from '../WidgetSearchFrame';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import useWindowDimensions from '../../app/hooks';
import { smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';

interface PerformancesParCategorieProps {
    shopId: number,
    widget: DashboardWidget
}

interface PerformancesParCategorieSlideProps {
    widget: DashboardWidget;
    shopId: number;
    slide: DashboardWidgetSlide;
    top: number;
}


export default function PerformancesParCategories({shopId, widget} : PerformancesParCategorieProps) {

    let [slides, setSlides] = useState<DashboardWidgetSlide[]>(getDateRange(new Date().toISOString(), 4, 0).map((el) => {return {debut: el, fin: el, timestamp: null}}));
    const top: number = 5;

    return (
       <div className="Widget">
            <WidgetHeader title="Performances par Catégories" widget={widget}/>
            {widget.search ? 
                    <WidgetSearchFrame widget={widget} withDateFin={false}>
                        {widget.searchDateDebut ?
                            <PerformancesParCategoriesSlide 
                                widget={widget}
                                shopId={shopId} 
                                slide={{debut: widget.searchDateDebut, fin: widget.searchDateDebut, timestamp: ""}}
                                top={top} /> 
                            : 
                            null
                        }   
                    </WidgetSearchFrame>
            :  
            <WidgetSlider widget={widget} defaultIndex={slides.map(el => el.debut).indexOf(dayjs().format('YYYY-MM-DD'))}>
                {slides.map(s => {
                    return (
                    <PerformancesParCategoriesSlide 
                        widget={widget}
                        shopId={shopId} 
                        slide={s} 
                        key={s.debut} 
                        top={top} />)
                })}
            </WidgetSlider>}
       </div>
    )
}


function PerformancesParCategoriesSlide ({shopId, slide, top, widget} : PerformancesParCategorieSlideProps) {

    const [data, setData] = useState<PerformancesData | null>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setData(null);
        getPerformancesParCategories(shopId, slide.debut, top, getRefresh(slide.timestamp, widget.refreshTime))
        .then((res) => {
            setData(res);
            slide.timestamp = res.timestamp;
        })
        .catch((err) => {
            console.log(err);
            setError(true);
        });
        
    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent
        style={{height:(useWindowDimensions().width < smallWidth ? 180 : 250 ) + getBarChartHeight(top) +'px'}} 
        data={data} 
        widget={widget} 
        error={error} 
        date={getDateAsString(slide.debut, slide.debut)}>
            {data == null ? 
                null :
                <div>
                    <DataBlock
                        valueN={data.nbArticleN}
                        valueN_1={data.nbArticleN_1}
                        libelle={"Total des articles dehors :"}/>
                    <ChartBlock
                        style={{height: getBarChartHeight(top) +'px'}}
                        widget={widget}
                        libelle={"Top "+top+" des catégories :"}
                        chartTypes={['bar', 'polar', 'radar']}
                        color='blue'
                        data={data.topCategories.map(el => {
                            return {
                                name:el.nom,
                                valueN:el.nbArticleN,
                                valueN_1:el.nbArticleN_1
                            }
                        })}/>
                </div>
            }
        </WidgetContent>
            
    )
}