import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store';
import { User } from '../models/user';
import { Shop } from '../models/shop';
import { Group } from '../models/group';
import { Widget } from '../models/widget';

interface UserState  {
    active:boolean,
    email: string,
    shops: Shop[],
    groups: Group[],
    widgets: Widget[]
}

const initialState: UserState = {
    active:false,
    email: '',
    shops: [],
    groups: [],
    widgets: []
  }

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action : PayloadAction<User>) => {
            return{
              ...state,
              active: true,
              email: action.payload.email,
              shops: action.payload.shops,
              groups: action.payload.groups,
              widgets: action.payload.widgets
            }
        },
        unsetUser: (state) => {
            return{
              ...state,
              active: false,
              email: '',
              shops: [],
              groups: [],
              widgets: [],
            }
        },
        updateWidgets: (state, action  : PayloadAction<Widget[]>) => {
            return{
              ...state,
              widgets: action.payload,
            }
        },
        updateGroups: (state, action  : PayloadAction<Group[]>) => {
            return{
              ...state,
              groups: action.payload,
            }
        },
    }

});

export const { setUser, unsetUser, updateWidgets, updateGroups } = userSlice.actions
export const selectUser = (state: RootState) => state.user
export default userSlice.reducer