import { CSSProperties } from "react";
import { ReactECharts } from "./ReactECharts";
import useWindowDimensions from "../../app/hooks";
import { smallWidth } from "../../utils/Const";


interface GaugeChartProps {
    percent: number;
    style?: CSSProperties;
}



export function GaugeChart ({percent, style} : GaugeChartProps){

    const {width} = useWindowDimensions();
    const height = width < smallWidth ? '100px' : '150px';
    const lineWidth = width < smallWidth ? 18 : 30 ;
    const fontSize = width < smallWidth ? 22 : 30 ;

    style = {
        ...style,
        height: height,
        width:'96%',
    }

    const options = {
        series: [
          {
            type: 'gauge',
            radius:'120%',
            center: ['50%', '80%'],
            startAngle: 180,
            endAngle: 0,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
            },
            axisLine: {
              lineStyle: {
                width: lineWidth
              }
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            data: [
              {
                value: percent,
                itemStyle: {
                  color: '#66ace6',
                },
                detail: {
                  fontSize: fontSize,
                  offsetCenter: [0, '-10%'],
                  valueAnimation: true,
                  formatter: function (value: number) {
                    return Math.round(value) + '%';
                  },
                  color: 'inherit'
                },
              }
            ],
            // title: {
            //   fontSize: 14
            // },
          }
        ]
      };
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}