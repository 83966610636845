
import { useNavigate } from 'react-router-dom';
import { EuroIcon, HeartIcon, PalmaresIcon, SkisIcon } from './Icons';
import '../styles/headers.css'

interface MainHeaderProps {
    title: string;
    children?: React.ReactNode;
}

interface DashboardHeaderProps {
    type: string;
    shopId: number;
}


export function MainHeader({title, children}: MainHeaderProps) {
    return (
       <div className="MainHeader header">
            <span>{title}</span>
            <div className='buttons'>
                {children}
            </div>
       </div>
    )
}

export function DashboardHeader({type, shopId}: DashboardHeaderProps) {

    const navigate = useNavigate();

    const onClickButton = (type: string) => {
        navigate('/'+type+'/'+shopId);
    }

    return (
       <div className="DashboardHeader header">
            <button className={type === "favoris" ? "selected" : ""} onClick={() => onClickButton('favoris')}><HeartIcon fill="currentColor"/></button>
            <button className={type === "location" ? "selected" : ""} onClick={() => onClickButton('location')}><SkisIcon/></button>
            <button className={type === "caisse" ? "selected" : ""} onClick={() => onClickButton('caisse')}><EuroIcon/></button>
            <button className={type === "palmares" ? "selected" : ""} onClick={() => onClickButton('palmares')}><PalmaresIcon/></button>
       </div>
    )
}