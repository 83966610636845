import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { formatLabels } from "../../utils/Charts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import { ChartColorTheme } from "../../models/ChartColorTheme";
import { smallWidth } from "../../utils/Const";
import useWindowDimensions from "../../app/hooks";

interface BarChartProps {
    data: ChartData[];
    colors: ChartColorTheme;
    style?: CSSProperties;
}



export function BarChart ({data, colors, style} : BarChartProps){

    const {width} = useWindowDimensions();

    const fontSize = width < smallWidth ? 10 : 12;

    style = {
        ...style,
        width:'96%'
    }
  
    let options: EChartOption = {
        grid: {
            left: 10,
            top: 0,
            right:10,
            bottom: 0,
            containLabel :true
        },
        xAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}',
                fontSize : fontSize,
            }
        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: formatLabels(data.map((el: ChartData) => el.name), 15),
            axisLabel: {
                interval : 0,
                height: 50,
                fontSize : fontSize,
    
            }
        },
        series: [
            {
                type: 'bar',
                data: data.map((el: ChartData) => el.valueN),
                label: {
                    show :true,
                    fontSize : fontSize,
                    position: 'inside',
                    color :colors.labelN
                },
                itemStyle : { 
                    color: colors.backgroundN,
                }
            },
            {

                type: 'bar',
                label:{
                    show: true,
                    fontSize : fontSize,
                    position: 'inside',
                    color :colors.labelN_1
                },
                data: data.map((el: ChartData) => el.valueN_1),
                itemStyle : { 
                    color: colors.backgroundN_1
                }
            }
        ]
        }
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}