import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';
import WidgetSlider from '../WidgetSlider';

import { PrevisionsSortiesData } from "../../models/shopData";
import {  getPrevisionsSorties } from '../../api/locationApi';
import { DataBlock } from '../DataBlock';
import dayjs from 'dayjs';

import { getDateAsString, getDateRange, getRefresh } from '../../utils/Widgets';
import WidgetSearchFrame from '../WidgetSearchFrame';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';

import useWindowDimensions from '../../app/hooks';
import { smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';

interface PrevisionsSortiesProps {
    shopId: number,
    widget: DashboardWidget
}

interface PrevisionsSortiesSlideProps {
    shopId: number;
    slide: DashboardWidgetSlide;
    top: number;
    widget: DashboardWidget
}


export default function PrevisionsSorties({shopId, widget} : PrevisionsSortiesProps) {

    let [slides, setSlides] = useState<DashboardWidgetSlide[]>(getDateRange(new Date().toISOString(), 4, 4).map((el) => {return {debut: el, fin: el, timestamp: null}}));
    const top: number = 5;

    return (
       <div className="Widget">
            <WidgetHeader title="Prévisions des sorties" widget={widget}/>
            {widget.search ? 
                    <WidgetSearchFrame widget={widget} withDateFin={false}>
                        {widget.searchDateDebut ?
                            <PrevisionsSortiesSlide 
                                widget={widget}
                                shopId={shopId} 
                                slide={{debut: widget.searchDateDebut, fin: widget.searchDateDebut, timestamp: ""}}
                                top={top} /> 
                            : null
                        }   
                    </WidgetSearchFrame>
            :  
            <WidgetSlider widget={widget} defaultIndex={slides.map(el => el.debut).indexOf(dayjs().format('YYYY-MM-DD'))}>
                {slides.map(s => {
                    return (
                    <PrevisionsSortiesSlide 
                        widget={widget}
                        shopId={shopId} 
                        slide={s} 
                        key={s.debut} 
                        top={top} />)
                })}
            </WidgetSlider>}
       </div>
    )
}


function PrevisionsSortiesSlide ({shopId, slide, top, widget} : PrevisionsSortiesSlideProps) {

    const [data, setData] = useState<PrevisionsSortiesData | null>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setData(null);
        getPrevisionsSorties(shopId, slide.debut, top, getRefresh(slide.timestamp, widget.refreshTime))
        .then((res) => {
            setData(res);
            slide.timestamp = res.timestamp;
        })
        .catch((err) => {
            console.log(err);
            setError(true);
        });

    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent
        style={{minHeight:(useWindowDimensions().width < smallWidth ? 170 : 220 ) + 'px'}} 
        data={data} 
        widget={widget} 
        error={error} 
        date={getDateAsString(slide.debut, slide.debut)}>
            {data == null ? 
                null :
                <div>
                    <DataBlock
                        valueN={data.nbArticleN}
                        valueN_1={data.nbArticleN_1}
                        libelle={"Articles réservés :"}/>
                    <DataBlock
                        valueN={data.nbArticleDirectN}
                        valueN_1={data.nbArticleDirectN_1}
                        libelle={"Articles loués en direct :"}/>
                </div>
            }
        </WidgetContent>

    )
}