
import { CSSProperties, useRef } from "react";
import ChartData from "../../models/chartData";
import { Chart, registerables } from "chart.js";
import { formatLabels } from "../../utils/Charts";
import { ChartColorTheme } from "../../models/ChartColorTheme";
import useWindowDimensions from "../../app/hooks";
import { smallWidth } from "../../utils/Const";

Chart.register(...registerables)

interface RadarChartProps {
    data: ChartData[];
    colors: ChartColorTheme;
    style?: CSSProperties;
}


export function RadarChart ({data, colors, style} : RadarChartProps){

    const {width} = useWindowDimensions();
    const fontSize = width < smallWidth ? 10 : 12;

    style= {
        ...style,
        width:'96%', 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center"
    }

    let textColor = '#5c5858'; //TODO : global

    const radarData = {
        labels: formatLabels(data.map(el => el.name),15),
        datasets: [
            {
                // label : labelN,
                data: data.map(el => el.valueN),
                fill: true,
                backgroundColor:  colors.backgroundN,
                borderColor:  colors.labelN,
                pointBackgroundColor:  colors.labelN,
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: colors.labelN
            }, 
            {
                // label : labelN_1,
                data: data.map(el => el.valueN_1),
                fill: true,
                backgroundColor: colors.backgroundN_1,
                borderColor: colors.labelN_1,
                pointBackgroundColor: colors.labelN_1,
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: colors.labelN_1
            }
        ]
    }

    //TODO : type
    const radarOptions: any = {
        plugins: {
            tooltip: {
                enabled : true,
                backgroundColor : '#fff',
                titleColor : textColor,
                bodyColor : textColor,
                borderWidth : 1,
                borderColor : textColor,
                displayColors:false,
                callbacks:{
                    title: function(context: any) {
                        return context[0].label ;
                    },
                    label: function(context: any) {
                        return context.formattedValue ;
                    }
                }
    
            },
            legend: {display : false}

        },
        scales: {
          r: {
            pointLabels: {
                // color:'var(--text-color)',
                font: {
                    size: fontSize,
                } 
            }
          }
        },
        elements: {
            line: {borderWidth: 1}
        },
      };

    const chartRef = useRef<Chart | null>(null);

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        if (!canvas || chartRef.current?.id != null) return;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            chartRef.current = new Chart(ctx, {
                type: "radar",
                data: radarData,
                options: radarOptions
            });
        }
      };

    return  (
        <div style={style}>
            <canvas ref={canvasCallback}></canvas>
        </div>
    )
  
}